import { useRouter } from "next/router";

import { loginPageLink } from "~/shared/utils/postLoginRedirectPath";

export const RedirectToLogin = () => {
  const { asPath: redirectPath, push } = useRouter();
  localStorage.clear();
  push(loginPageLink({ redirectPath }));
  return null;
};
