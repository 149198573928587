import type { NextPage } from "next";
import { useRouter } from "next/router";

import { RedirectToLogin } from "~/components/RedirectToLogin";
import WaitRoom from "~/containers/Common/WaitRoom";
import { useUserContext } from "~/contexts/UserContext";
import { redirectFromRoot } from "~/shared/utils/postLoginRedirectPath";

/**
 * Our current implementation is that you are automatically always routed
 * to your first workspace if you're logged in and are in a workspace, get started
 * if you're new without a workspace or login if you're not logged in, we want to
 *
 */
const RootRedirect: NextPage = () => {
  const { authenticated, loading, user } = useUserContext();
  const { query, push } = useRouter();

  if (!authenticated) {
    if (loading) {
      return <WaitRoom />;
    }
    return <RedirectToLogin />;
  }

  push(redirectFromRoot({ query, user }));
  return null;
};

export default RootRedirect;
