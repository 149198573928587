/**
 * These utilities help encode and decode redirect paths in login request data
 */

import type { ObjectId } from "mongodb";

const redirectPathKey = "redirect_path";

/**
 * An ObjectWithRedirectPath might be an OAuth state object (Google/WorkOS), a parsed querystring object, or a parsed request body object
 */
type ObjectWithRedirectPath = Record<string, unknown> & {
  [redirectPathKey]?: string | undefined;
};

/**
 * Return a URL to the login page which will then redirect the user to the target path
 */
export function loginPageLink({
  redirectHost,
  redirectPath,
  preview = false,
}: {
  redirectHost?: string;
  redirectPath?: string;
  preview?: boolean;
}) {
  const basePath = `/login`;

  return buildPath({
    basePath,
    redirectHost,
    redirectPath,
    preview,
  });
}

/**
 * Return a URL to the login API endpoint that encodes the redirect path
 */
export function loginAPIEndpoint({
  betaInvitation,
  redirectPath,
  redirectHost,
  preview,
}: {
  betaInvitation?: string;
  redirectPath?: string;
  redirectHost?: string;
  preview?: boolean;
}) {
  const basePath = `/api/login`;

  const path = buildPath({
    basePath,
    betaInvitation,
    redirectPath,
    redirectHost,
    preview,
  });
  return path;
}

export function buildPath({
  basePath,
  betaInvitation,
  redirectPath,
  redirectHost,
  preview,
}: {
  basePath: string;
  betaInvitation?: string;
  redirectHost?: string;
  redirectPath?: string;
  preview?: boolean;
}) {
  let qs;

  const hasRedirect =
    redirectPath && redirectPath !== "/" && redirectPath !== "/login";
  if (hasRedirect) {
    qs = `${redirectPathKey}=${encodeURI(redirectPath || "")}`;
  }

  if (betaInvitation) {
    const betaInvitationParam = `betaInvitation=${betaInvitation}`;
    qs = qs ? `${qs}&${betaInvitationParam}` : `${betaInvitationParam}`;
  }

  if (preview) {
    const previewURL = `https://${
      process.env.NEXT_PUBLIC_PREVIEW_ENVIRONMENT_LOGIN_HOSTNAME + basePath
    }?redirect_host=${encodeURI(redirectHost || "")}`;
    return qs ? `${previewURL}&${qs}` : previewURL;
  }

  return qs ? `${basePath}?${qs}` : basePath;
}

/**
 * Extract a redirect path from a state object, request query object, or request body object
 */
export function getRedirectPath(
  obj: ObjectWithRedirectPath
): string | undefined {
  return obj[redirectPathKey];
}

/**
 * Add a redirect path to a state object, request query object, or request body object
 */
export function addRedirectPath(
  obj: ObjectWithRedirectPath,
  path?: string
): ObjectWithRedirectPath {
  if (path) {
    obj[redirectPathKey] = path;
  }
  return obj;
}

/**
 * Construct a WorkOS Magic Link state string that encodes a redirect path
 */
export function magicLinkStateString({
  betaInvitation,
  redirectPath,
}: {
  betaInvitation?: string;
  redirectPath?: string;
}) {
  let stateString;
  if (redirectPath) {
    stateString = `${redirectPathKey}=${redirectPath}`;
  }

  if (betaInvitation) {
    const betaInvitationParam = `betaInvitation=${betaInvitation}`;
    stateString = stateString
      ? `${stateString}&${betaInvitationParam}`
      : betaInvitationParam;
  }

  return stateString;
}

export function redirectFromRoot({
  query,
  user,
}: {
  query: { [redirectPathKey]?: string };
  user: {
    workspaces?: Array<{ _id: string | ObjectId }>;
    guestOfWorkspaces?: Array<{ _id: string | ObjectId }>;
    workspaceInvitations?: Array<{ _id: string | ObjectId }>;
    proposalInvitations?: Array<{ linkId: string }>;
  };
}) {
  const redirectPath = getRedirectPath(query);

  if (redirectPath) {
    return redirectPath;
  }

  if (user.workspaces?.length) {
    return `/${user.workspaces[0]._id}`;
  }

  if (user.guestOfWorkspaces?.length) {
    return `/${user.guestOfWorkspaces?.[0]._id}`;
  }

  if (user.workspaceInvitations?.length || user.proposalInvitations?.length) {
    return "/welcome/accept-invite";
  }

  return "/welcome/switch-workspaces";
}
